import React, { useEffect } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Select,
  Flex,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import xhr from 'xhr'
import Loading from 'components/loading/Loading'
import ReactPaginate from 'react-paginate'
import dayjs from 'dayjs'

import { downloadExcel } from 'react-export-table-to-excel'
dayjs.locale('es')

export default function EstudiantesAtrasados() {
  const [months, setMonths] = React.useState([])
  const [programs, setPrograms] = React.useState([])
  const [currentMonth, setCurrentMonth] = React.useState()
  const [currentProgramType, setCurrentProgramType] = React.useState()
  const [currentProgramT, setCurrentProgramT] = React.useState()
  const [currentProgram, setCurrentProgram] = React.useState()
  const [programTypes, setProgramTypes] = React.useState([])
  const [students, setStudents] = React.useState([])
  const [fetching, setFetching] = React.useState(true)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [studentsCount, setStudentsCount] = React.useState(0)
  const [downloading, setDownloading] = React.useState(false)

  useEffect(() => {
    ;(async () => {
      // const res = await xhr(`/months`)
      const resP = await xhr(`/program-types`)
      setProgramTypes(
        resP.data.data.map((el) => ({
          label: el.name,
          id: el.id,
          type: el.type,
          ...el,
        }))
      )
      // setMonths(
      //   res.data.data.map((el) => ({
      //     label: el.name,
      //     id: el.id,
      //   }))
      // )
      setFetching(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (currentProgramType) {
        const currentProgramT = programTypes.find(
          (el) => el.id === Number(currentProgramType)
        )
        setCurrentProgramT(currentProgramT)
        const resP = await xhr(
          `/${currentProgramT.type.replace(
            '_',
            '-'
          )}s?filters[program_type][id][$eq]=${currentProgramT.id}`
        )
        setPrograms(
          resP.data.data.map((el) => ({
            label: el.name,
            id: el.id,
            type: el.type,
            ...el,
          }))
        )
      }
    })()
  }, [currentProgramType])

  const handleSearch = async () => {
    const resP = await xhr(
      `/month-students?filters[${currentProgramT.type}_enrollment][${currentProgramT.type}][id][$eq]=${currentProgram}&filters[$or][0][on_day][$eq]=false&filters[$or][1][on_day][$null]=true&populate=months&populate=student.user&populate=${currentProgramT.type}_enrollment.${currentProgramT.type}&pagination[page]=${currentPage}&pagination[pageSize]=20`
    )
    setStudents(resP.data.data)
    setStudentsCount(resP.data.meta.pagination.pageCount)
  }

  React.useEffect(() => {
    if (currentProgramT) {
      handleSearch(currentPage)
    }
  }, [currentPage])

  if (fetching) return <Loading />

  const monthActual = dayjs().format('MMMM')

  const monthCapitalize = `${monthActual[0].toUpperCase()}${monthActual.slice(
    1
  )}`

  const filteredStudents = students.filter(
    (student) => !student.months.some((month) => month.name === monthCapitalize)
  )

  const handleDownloadExcel = async () => {
    setDownloading(true)

    const resP = await xhr(
      `/month-students?filters[${currentProgramT.type}_enrollment][${currentProgramT.type}][id][$eq]=${currentProgram}&filters[$or][0][on_day][$eq]=false&filters[$or][1][on_day][$null]=true&populate=months&populate=student.user&populate=${currentProgramT.type}_enrollment.${currentProgramT.type}&pagination[limit]=-1`
    )

    const filteredStudents = resP.data.data.filter(
      (student) =>
        !student.months.some((month) => month.name === monthCapitalize)
    )

    const body = filteredStudents.map((item) => ({
      id: item.student.id,
      names: `${item.student.user.first_name} ${item.student.user.last_name}`,
      cellphome: item?.student?.user?.cellphone,
      email: item?.student?.user?.email,
      program: `${
        item[`${currentProgramT?.type}_enrollment`][currentProgramT?.type]?.name
      } `,
    }))

    let header = ['ID', 'NOMBRES', 'CEULAR', 'CORREO', 'PROGRAMA']

    downloadExcel({
      fileName: `atrasados-csv`,
      sheet: 'excel',
      tablePayload: {
        header,
        body: body,
      },
    })

    setDownloading(false)
  }

  return (
    <>
      <Box fontSize="29px" fontWeight="bold">
        Estudiantes atrasados
      </Box>

      <Flex gap={5}>
        {/* <Select
          value={currentMonth}
          onChange={(e) => setCurrentMonth(e.target.value)}
        >
          <option>Seleccione el mes</option>
          {months.map((el) => (
            <option value={el.id}> {el.label}</option>
          ))}
        </Select> */}

        <Select
          value={currentProgramType}
          onChange={(e) => setCurrentProgramType(e.target.value)}
        >
          <option>Seleccione el tipo de programa</option>
          {programTypes.map((el) => (
            <option value={el.id} key={el.id}>
              {' '}
              {el.label}
            </option>
          ))}
        </Select>

        <Select
          value={currentProgram}
          onChange={(e) => setCurrentProgram(e.target.value)}
        >
          <option>Seleccione el programa</option>
          {programs.map((el) => (
            <option value={el.id} key={el.id}>
              {' '}
              {el.label}
            </option>
          ))}
        </Select>

        <Button colorScheme="messenger" w="200px" onClick={handleSearch}>
          Buscar
        </Button>
      </Flex>

      <Box mt="15px">
        {filteredStudents.length > 0 && (
          <Button
            colorScheme="green"
            isDisabled={downloading}
            isLoading={downloading}
            onClick={() => handleDownloadExcel()}
            mb="15px"
          >
            Descargar excel
          </Button>
        )}
        <Box>
          <TableContainer>
            <Table marginY="sm">
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombre</Th>
                  <Th>Meses Pagados</Th>
                  <Th>Programa</Th>
                  <Th>Perfil</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredStudents.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item.student.id}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${item.student.user.first_name} ${item.student.user.last_name}`}
                        </Box>
                      </Td>
                      <Td>
                        {item.months.map((el) => (
                          <Box key={el.id}>
                            <Box>{el.name}</Box>
                          </Box>
                        ))}
                      </Td>

                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${
                            item[`${currentProgramT?.type}_enrollment`][
                              currentProgramT?.type
                            ]?.name
                          } `}
                        </Box>
                      </Td>

                      <Td>
                        <Link
                          style={{ color: 'blue' }}
                          to={`/plataforma/administrador/ver-estudiante/${item.student.id}`}
                        >
                          Ver
                        </Link>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Flex justifyContent="flex-end" marginTop="sm">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1)
          }}
          pageRangeDisplayed={2}
          pageCount={studentsCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="containerReactPaginate"
          pageClassName="liReactPaginate"
        />
      </Flex>

      <style jsx>{`
        .containerReactPaginate {
          display: flex;
          width: inherit;
          justify-content: space-evenly;
          list-style: none;
          width: 300px;
        }

        .selected {
          color: #574feb;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}
