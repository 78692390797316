import { Route, Routes } from 'react-router-dom'
// layouts
import MainLayout from './layout/Main'
import AdminLayout from './layout/Admin'
import HomeWithIndex from 'pages/estudiantes'
// import MainLayout from '../layouts/main';
// import DashboardLayout from "../src/layouts/dashboard";
// guards
import GuestGuard from '../src/guards/GuestGuard'
import Results from 'pages/estudiantes/type/program/resultados'
import Requirements from 'pages/estudiantes/type/program/requisitos'
import Comunidad from 'pages/estudiantes/type/program/comunidad'
import RecordedClasses from 'pages/estudiantes/type/program/clases-grabadas'
import ClassDetailWithClass from 'pages/estudiantes/clases/[type]/[program]/[subject]/[module]/[class]'
import ContentInsideProgram from 'pages/estudiantes/programas/[id]'
import ProgramDetailSlug from 'pages/estudiantes/programa/[slug]'
import OtherPaymentsContainer from 'pages/estudiantes/type/program/otros-pagos'
import EditAccount from 'pages/estudiantes/editar-cuenta'
import ShoppingCart from 'pages/estudiantes/carrito'
import PurchaseOrderContainer from 'pages/estudiantes/orden-de-compra'
import Buzon from 'pages/estudiantes/buzon-sugerencias'
import Contact from 'pages/estudiantes/contactenos'
import HomeAdmin from 'pages/administrador'
import VerEstudiante from 'pages/administrador/ver-estudiante/[id]'
import VerCurso from 'pages/administrador/ver-resultados'
import EmailTemplateEditor from 'pages/administrador/ver-estudiante/[id]/components/enviarEmail'
import VerRequisitos from 'pages/administrador/ver-estudiante/[id]/ver-requisitos'
import Payments from 'pages/estudiantes/pagos'
import Referals from 'pages/estudiantes/referidos'
import ChatContentInside from 'pages/estudiantes/chat'
import NotificationWithLayout from 'pages/estudiantes/notificaciones'
import ModifyStudentAdmin from 'pages/administrador/modificar-estudiante'
import BuscarFiltro from 'pages/administrador/filtro/buscar-filtro'
import Filtro from 'pages/administrador/filtro/filtro'
import LiveClassAdmin from 'pages/administrador/clases-en-vivo'
import SubirClases from 'pages/administrador/clases-en-vivo/SubirClases'
import StudentGroupAdmin from 'pages/administrador/grupos-estudiantes'
import CampaignsIndex from 'pages/administrador/emails/envios'
import IdCampaign from 'pages/administrador/emails/envios/id'
import EmailTemplateEditorAdmin from 'pages/administrador/emails/envios/id/editor'
import TemplatesAdmin from 'pages/administrador/emails/plantillas'
import EmailTemplateEditorAdmin2 from 'pages/administrador/emails/plantillas/[id]/editor'
import PaymentsPayUAdmin from 'pages/administrador/pagos'
import FacturedPaymentsAdmin from 'pages/administrador/pagos/pagos-facturas'
import PurchaseOrdersAdmin from 'pages/administrador/pagos/ordenes-de-compra'
import FeedbackAdmin from 'pages/administrador/feedback'
import Efecty from 'pages/administrador/efecty'
import AfiliadosAdmin from 'pages/administrador/afiliados'
import DataAfiliadoId from 'pages/administrador/afiliados/[id]'
import BienvenidasAdmin from 'pages/administrador/bienvenidas'
import RequerimentsAdmin from 'pages/administrador/requisitos-pendientes'
import MainLayoutTeacher from 'layout/Profesores'
import LandingProfesores from 'pages/profesores'
import VerEstudianteTeacher from 'pages/profesores/ver-estudiante/[id]'
import VerResultadosTeacher from 'pages/profesores/ver-resultados'
import AsksTeacher from 'pages/profesores/preguntas'
import ViewMessageTeacher from 'pages/profesores/ver-mensaje'
import ActivitiesPageTeacher from 'pages/profesores/actividades'
import HomeAsistenciasTeacher from 'pages/profesores/asistencias'
import EscogerClases from 'pages/profesores/asistencias/escoger-clases'
import TomarAsistenciaTeacher from 'pages/profesores/asistencias/tomar-asistencia'
import ChatContentInsideTeacher from 'pages/profesores/chat'
import NotificationTeacher from 'pages/profesores/notificaciones'
import CrearBlogTeacher from 'pages/profesores/crear-blog'
import SearchCommunity from 'pages/profesores/buscar-comunidad'
import ComunidadTeacher from 'pages/profesores/comunidad'
import SearchStudentNotesTeacher from 'pages/profesores/buscar-estudiante/SearchStudentNotes'
import LayoutContentAdmin from 'layout/Content'
import HomeContentAdmin from 'pages/contenido'
import InsertSubjectsContent from 'pages/contenido/subject'
import MateriaContentTeacher from 'pages/contenido/idSubject'
import MaterialContentByModuleId from 'pages/contenido/idSubject/[module]'
import LandingAcudientes from 'pages/acudientes'
import ViewStudentParent from 'pages/acudientes/idEst'
import LayoutContentParent from 'layout/Acudientes'
import ResultsParent from 'pages/acudientes/idEst/ver-resultados'
import AttendancesStudent from 'pages/acudientes/idEst/asistencia'
import ComunidadParent from 'pages/acudientes/idEst/comunidad'
import PagosParent from 'pages/acudientes/idEst/pagos'
import RequisitosParent from 'pages/acudientes/idEst/requisitos'
import HomeAsesoras from 'pages/asesoras'
import MainLayoutAsesoras from 'layout/Asesoras'
import SearchStudentAdviser from 'pages/asesoras/consultar-estudiante.page'
import VerEstudianteAdviser from 'pages/asesoras/ver-estudiante/[id]'
import ResultsAdviser from 'pages/asesoras/ver-estudiante/[id]/ver-resultados'
import VerRequisitosAdviser from 'pages/asesoras/ver-estudiante/[id]/ver-requisitos'
import ModifyStudentAdviser from 'pages/asesoras/ver-estudiante/[id]/modificar-estudiante'
import UsersAdviser from 'pages/asesoras/estudiantes-registrados'
import VerProspecto from 'pages/asesoras/ver-prospecto/[id]/index'
import NewsStudents from 'pages/asesoras/nuevos-estudiantes'
import HomeAffiliate from 'pages/afiliados'
import MainLayoutAffiliate from 'layout/Affiliate'
import Configuracion from 'pages/afiliados/configuracion'
import Clientes from 'pages/afiliados/clientes'
import PagosAfiliate from 'pages/afiliados/pagos'
import ReferralLink from 'pages/afiliados/enlace-referido'
import RecoverPassword from 'pages/recuperar-password'
import Error404 from 'components/404'
import LiveClassesProgram from 'pages/estudiantes/type/program/clases-en-vivo'
import StudentPublicBadge from 'pages/publico/insignias/[badge]'
import Login from 'pages/ingresar/Login'
import Explorar from 'pages/estudiantes/programas'
import ProgramIndex from './pages/estudiantes/type/program'
import PurchaseOrdersStudent from 'pages/estudiantes/ordenes-de-compra'
import TabsHome from 'pages/estudiantes/home/tabs'
import RecordedClass from 'pages/estudiantes/type/program/clases-grabadas/[id]'
import WelcomeWithauth from 'pages/welcome'
import ChangePassword from 'pages/cambiar-contrasena'
import Prospectos from 'pages/administrador/filtro/prospects'
import Atrasados from 'pages/administrador/filtro/atrasados'
import Unscribe from 'pages/unscribe'
import StudentActivities from 'pages/administrador/ver-estudiante/[id]/viewActivity'
import ViewPrograms from 'pages/estudiantes/home/viewPrograms'
import GetDataStudentsWithoutEnrollment from 'pages/administrador/filtro/metricas/getDataStudentsWithoutEnrollment'
import SubirEstudiantes from 'pages/administrador/filtro/subir-estudiantes'
import HistoryPayments from 'pages/administrador/historial-de-pagos'
import CrearPizarron from 'pages/administrador/pizarron'
import EstudiantesConvenios from 'pages/afiliados/estudiantesConvenios'
import ConfiguracionConvenio from 'pages/afiliados/configuracionConvenio'
import VerEstudianteConvenio from 'pages/administrador/ver-estudiante-convenio/[id]'
import ConveniosAdmin from 'pages/administrador/convenios/convenios'
import VerEstudianteConvenios from 'pages/administrador/convenios/[id]/ver-estudiantes-convenios'
import ActividadesAI from 'pages/administrador/actividades-ai/convenios'
import ViewDataTeacher from 'pages/administrador/profesor/viewDataTeacher'
import ConveniosPDF from 'pages/afiliados/estudiantesConvenios/pdfs'
import CrearPodcast from 'pages/administrador/crear-podcast'
import EstudiantesAtrasados from 'pages/administrador/estudiantes-atrasados'

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      <Route path="*" element={<Error404 />} />
      <Route path="/" element={<GuestGuard />} />
      <Route path="unscribe" element={<Unscribe />} />
      <Route path="welcome" element={<WelcomeWithauth />} />
      <Route
        path="plataforma/recuperar-password"
        element={<RecoverPassword />}
      />
      <Route
        path="plataforma/cambiar-contrasena"
        element={<ChangePassword />}
      />
      <Route
        path="plataforma/publico/insignias/:id"
        element={<StudentPublicBadge />}
      />
      <Route path="plataforma" element={<GuestGuard />} />
      <Route path="plataforma">
        <Route
          path="ingresar"
          element={
            <GuestGuard>
              <Login />
            </GuestGuard>
          }
        />
        <Route
          path="recuperar-password"
          element={
            <GuestGuard>
              <RecoverPassword />
            </GuestGuard>
          }
        />
        <Route element={<HomeWithIndex />}>
          <Route element={<MainLayout />}>
            <Route path="estudiantes" element={<TabsHome />} />
            <Route
              path="estudiantes/mis-programas"
              element={<ViewPrograms />}
            />
            <Route path="estudiantes/carrito" element={<ShoppingCart />} />
            <Route path="estudiantes/pagos" element={<Payments />} />
            <Route path="estudiantes/chat" element={<ChatContentInside />} />
            <Route
              path="estudiantes/notificaciones"
              element={<NotificationWithLayout />}
            />
            <Route path="estudiantes/referidos" element={<Referals />} />
            <Route
              path="estudiantes/orden-de-compra"
              element={<PurchaseOrderContainer />}
            />
            <Route
              path="estudiantes/ordenes-de-compra"
              element={<PurchaseOrdersStudent />}
            />
            <Route path="estudiantes/buzon-sugerencias" element={<Buzon />} />
            <Route path="estudiantes/contactenos" element={<Contact />} />
            <Route path="estudiantes/editar-cuenta" element={<EditAccount />} />
            <Route path="estudiantes/programas" element={<Explorar />} />
            <Route
              path="estudiantes/programas/:id"
              element={<ContentInsideProgram />}
            />
            <Route
              path="estudiantes/programa/:slug"
              element={<ProgramDetailSlug />}
            />
            <Route
              path="estudiantes/:type/:program"
              element={<ProgramIndex />}
            />
            <Route
              path="estudiantes/:type/:program/clases-en-vivo"
              element={<LiveClassesProgram />}
            />
            <Route
              path="estudiantes/:type/:program/resultados"
              element={<Results />}
            />
            <Route
              path="estudiantes/:type/:program/requisitos"
              element={<Requirements />}
            />
            <Route
              path="estudiantes/:type/:program/comunidad"
              element={<Comunidad />}
            />
            <Route
              path="estudiantes/:type/:program/clases-grabadas"
              element={<RecordedClasses />}
            />
            <Route
              path="estudiantes/:type/:program/clases-grabadas/:id"
              element={<RecordedClass />}
            />
            <Route
              path="estudiantes/:type/:program/otros-pagos"
              element={<OtherPaymentsContainer />}
            />
            <Route
              path="estudiantes/clases/:type/:program/:subject/:module/:class"
              element={<ClassDetailWithClass />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<AdminLayout />}>
            <Route path="administrador" element={<HomeAdmin />} />
            <Route
              path="administrador/modificar-estudiante/:id"
              element={<ModifyStudentAdmin />}
            />
            <Route
              path="administrador/crear-blog"
              element={<CrearBlogTeacher />}
            />

            <Route
              path="administrador/crear-podcast"
              element={<CrearPodcast />}
            />

            <Route path="administrador/pizarron" element={<CrearPizarron />} />
            <Route
              path="administrador/profesores"
              element={<ViewDataTeacher />}
            />
            <Route
              path="administrador/ver-estudiante/:id"
              element={<VerEstudiante />}
            />
            <Route
              path="administrador/ver-estudiante-convenio/:id"
              element={<VerEstudianteConvenio />}
            />

            <Route
              path="administrador/ver-estudiante/:id/ver-requisitos"
              element={<VerRequisitos />}
            />
            <Route
              path="administrador/ver-estudiante/:id/enviar-email"
              element={<EmailTemplateEditor />}
            />
            <Route
              path="administrador/ver-resultados/:type/:program"
              element={<VerCurso />}
            />
            <Route
              path="administrador/ver-estudiante/:id/ver-actividad"
              element={<StudentActivities />}
            />
            <Route path="administrador/filtro" element={<Filtro />} />
            <Route
              path="administrador/filtro/estudiantes"
              element={<GetDataStudentsWithoutEnrollment />}
            />
            <Route
              path="administrador/subir-estudiantes"
              element={<SubirEstudiantes />}
            />
            <Route path="administrador/atrasados" element={<Atrasados />} />
            <Route path="administrador/prospects" element={<Prospectos />} />
            <Route
              path="administrador/clases-en-vivo"
              element={<LiveClassAdmin />}
            />
            <Route
              path="administrador/clases-en-vivo/subir-clases"
              element={<SubirClases />}
            />
            <Route
              path="administrador/filtro/buscar-semestre"
              element={<BuscarFiltro />}
            />
            <Route
              path="administrador/grupos-estudiantes"
              element={<StudentGroupAdmin />}
            />
            <Route
              path="administrador/emails/envios"
              element={<CampaignsIndex />}
            />
            <Route
              path="administrador/emails/plantillas"
              element={<TemplatesAdmin />}
            />
            <Route
              path="administrador/emails/plantillas/:id/editor"
              element={<EmailTemplateEditorAdmin2 />}
            />
            <Route
              path="administrador/emails/envios/:id"
              element={<IdCampaign />}
            />
            <Route
              path="administrador/emails/envios/:id/editor"
              element={<EmailTemplateEditorAdmin />}
            />
            <Route path="administrador/pagos" element={<PaymentsPayUAdmin />} />
            <Route
              path="administrador/pagos-facturas"
              element={<FacturedPaymentsAdmin />}
            />
            <Route
              path="administrador/ordenes-de-compra"
              element={<PurchaseOrdersAdmin />}
            />
            <Route path="administrador/feedback" element={<FeedbackAdmin />} />
            <Route
              path="administrador/requisitos-pendientes"
              element={<RequerimentsAdmin />}
            />
            <Route
              path="administrador/historial-de-pagos"
              element={<HistoryPayments />}
            />
            <Route path="administrador/efecty" element={<Efecty />} />
            <Route
              path="administrador/afiliados"
              element={<AfiliadosAdmin />}
            />
            <Route
              path="administrador/afiliados/:id"
              element={<DataAfiliadoId />}
            />
            <Route
              path="administrador/bienvenidas"
              element={<BienvenidasAdmin />}
            />
            <Route
              path="administrador/estudiantes-atrasados"
              element={<EstudiantesAtrasados />}
            />

            <Route
              path="administrador/actividades-ai"
              element={<ActividadesAI />}
            />
            <Route
              path="administrador/convenios"
              element={<ConveniosAdmin />}
            />
            <Route
              path="administrador/convenios/:id"
              element={<VerEstudianteConvenios />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<MainLayoutTeacher />}>
            <Route path="profesores" element={<LandingProfesores />} />
            <Route
              path="profesores/ver-estudiante/:id"
              element={<VerEstudianteTeacher />}
            />
            <Route
              path="profesores/ver-resultados/:type/:program"
              element={<VerResultadosTeacher />}
            />
            <Route path="profesores/preguntas" element={<AsksTeacher />} />
            <Route
              path="profesores/actividades"
              element={<ActivitiesPageTeacher />}
            />
            <Route
              path="profesores/chat"
              element={<ChatContentInsideTeacher />}
            />
            <Route
              path="profesores/ver-mensaje/:id"
              element={<ViewMessageTeacher />}
            />
            <Route
              path="profesores/asistencias"
              element={<HomeAsistenciasTeacher />}
            />
            <Route
              path="profesores/asistencias/escoger-clases"
              element={<EscogerClases />}
            />
            <Route
              path="profesores/asistencias/escoger-clases/tomar-asistencia"
              element={<TomarAsistenciaTeacher />}
            />
            <Route
              path="profesores/notificaciones"
              element={<NotificationTeacher />}
            />
            <Route
              path="profesores/crear-blog"
              element={<CrearBlogTeacher />}
            />
            <Route
              path="profesores/buscar-estudiante"
              element={<SearchStudentNotesTeacher />}
            />
            <Route path="profesores/comunidad" element={<ComunidadTeacher />} />
            <Route
              path="profesores/buscar-comunidad"
              element={<SearchCommunity />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<LayoutContentAdmin />}>
            <Route path="contenido" element={<HomeContentAdmin />} />
            <Route
              path="contenido/subject"
              element={<InsertSubjectsContent />}
            />
            <Route
              path="contenido/:idSubject"
              element={<MateriaContentTeacher />}
            />
            <Route
              path="contenido/:idSubject/:idModule"
              element={<MaterialContentByModuleId />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<LayoutContentParent />}>
            <Route path="acudientes" element={<LandingAcudientes />} />
            <Route path="acudientes/:idEst" element={<ViewStudentParent />} />
            <Route
              path="acudientes/ver-resultados/:idEst/:type/:program"
              element={<ResultsParent />}
            />
            <Route
              path="acudientes/asistencia/:idEst/:type/:idType/:name"
              element={<AttendancesStudent />}
            />
            <Route
              path="acudientes/:idEst/comunidad/:type/:program"
              element={<ComunidadParent />}
            />
            <Route path="acudientes/:idEst/pagos" element={<PagosParent />} />
            <Route
              path="acudientes/:idEst/requisitos"
              element={<RequisitosParent />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<MainLayoutAsesoras />}>
            <Route path="asesoras" element={<HomeAsesoras />} />
            <Route
              path="asesoras/ver-prospecto/:id"
              element={<VerProspecto />}
            />
            <Route
              path="asesoras/nuevos-estudiantes"
              element={<NewsStudents />}
            />
            <Route
              path="asesoras/consultar-estudiante"
              element={<SearchStudentAdviser />}
            />
            <Route
              path="asesoras/ver-estudiante/:id"
              element={<VerEstudianteAdviser />}
            />
            <Route
              path="asesoras/ver-estudiante/:id/ver-resultados/:type/:program"
              element={<ResultsAdviser />}
            />
            <Route
              path="asesoras/ver-estudiante/:id/ver-requisitos"
              element={<VerRequisitosAdviser />}
            />
            <Route
              path="asesoras/ver-estudiante/:id/modificar-estudiante"
              element={<ModifyStudentAdviser />}
            />
            <Route
              path="asesoras/estudiantes-registrados"
              element={<UsersAdviser />}
            />
            <Route
              path="asesoras/prospectos-landing"
              element={<Prospectos />}
            />
          </Route>
        </Route>

        <Route element={<HomeWithIndex />}>
          <Route element={<MainLayoutAffiliate />}>
            <Route path="afiliados" element={<HomeAffiliate />} />
            <Route path="afiliados/configuracion" element={<Configuracion />} />
            <Route path="afiliados/clientes" element={<Clientes />} />
            <Route path="afiliados/pagos" element={<PagosAfiliate />} />
            <Route
              path="afiliados/configuracion-convenio"
              element={<ConfiguracionConvenio />}
            />
            <Route
              path="afiliados/estudiantes-convenios"
              element={<EstudiantesConvenios />}
            />
            <Route path="afiliados/conveniospdf" element={<ConveniosPDF />} />
            <Route
              path="afiliados/enlace-referido"
              element={<ReferralLink />}
            />
            <Route
              path="afiliados/ver-estudiante/:id/ver-resultados/:type/:program"
              element={<ResultsAdviser />}
            />
            <Route
              path="afiliados/ver-estudiante/:id/ver-requisitos"
              element={<VerRequisitosAdviser />}
            />
            <Route
              path="afiliados/ver-estudiante/:id/modificar-estudiante"
              element={<ModifyStudentAdviser />}
            />
            <Route
              path="afiliados/estudiantes-registrados"
              element={<UsersAdviser />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
