import React from 'react'
import {
  Box,
  Flex,
  Checkbox,
  Button,
  useToast,
  Spinner,
  Select,
} from '@chakra-ui/react'
import xhr from 'xhr'

export default function ModalSubject(props) {
  const {
    idEst,
    elPayment,
    mutate,
    payments,
    reload,
    setReload,
    setConceptListIdLast,
    conceptListIdLast,
    pagos,
    setSubjectsEnrollmentsWithoutFilter,
    subjectsEnrollmentsWithoutFilter,
    noremove,
    setIsOpen2,
    payUpayment,
  } = props

  const toasts = useToast()
  const [programs, setPrograms] = React.useState([])
  const [fetching, setFetching] = React.useState(false)
  const [fetching2, setFetching2] = React.useState(false)
  const [activeProgram, setActiveProgram] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [subjects, setSubjects] = React.useState([])
  const [currentProgram, setCurrentProgram] = React.useState({})
  const [subjectEnrollments, setSubjectsEnrollments] = React.useState([])
  const [conceptList, setConceptList] = React.useState([])
  const [isMenu, setIsMenu] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [isPost, setIsPost] = React.useState(false)

  const [currentPeriod, setCurrentPeriod] = React.useState('primer')
  const [periodText, setPeriodText] = React.useState('')
  const [subjectsPeriod, setSubjectsPeriod] = React.useState([])
  const [isFetchingSubjectsEnroll, setIsFetchingSubjectsEnroll] =
    React.useState(false)

  const handleProgram = async (value) => {
    setValue(value)
    try {
      if (value) {
        setActiveProgram(true)
        setFetching2(true)
        const program = programs.find(
          (el) =>
            Number(el.id) === Number(value.split('-')[1]) &&
            el.program_type.id === Number(value.split('-')[0])
        )

        let response
        let subjectsByPeriod

        if (program.is_category) {
          const ada = await xhr(
            `/course-enrollments?filters[course][id][$eq]=${program.id}&filters[student][id][$eq]=${idEst}&populate=course.courses`
          )
          response = ada.data.data[0].course.courses
          setSubjects(response)
        } else {
          response = await xhr(
            `/subjects?filters[${program.program_type.type}][id][$eq]=${program.id}&filters[visible][$eq]=true&populate=*&pagination[limit]=-1`
          )

          subjectsByPeriod = response.data.data.reduce((acc, current) => {
            if (acc[`${current.period}`]) {
              return {
                ...acc,
                [`${current.period}`]: [...acc[`${current.period}`], current],
              }
            }

            return { ...acc, [`${current.period}`]: [current] }
          }, {})

          setSubjects(response.data.data)
        }

        setSubjectsPeriod(subjectsByPeriod)

        setCurrentProgram(program)
        setFetching2(false)
      } else {
        setActiveProgram(false)
      }
    } catch (error) {}
  }

  React.useEffect(() => {
    ;(async () => {
      if (subjects.length > 0) {
        const program = programs.find(
          (el) =>
            Number(el.id) === Number(value.split('-')[1]) &&
            el.program_type.id === Number(value.split('-')[0])
        )
        const isAnnualProgram =
          program.program_type.slug ===
            'bachillerato-anual-para-adolescentes' ||
          program.program_type.slug === 'primaria-anual-para-ninos' ||
          program.program_type.slug === 'bachillerato-flexible'
        const isSubjectPeriod =
          program.program_type.type === 'technical_career' || isAnnualProgram

        setIsMenu(isSubjectPeriod)

        const subjectsRes = isSubjectPeriod
          ? subjectsPeriod[currentPeriod]
          : subjects

        if (isSubjectPeriod) {
          setSubjects(subjectsRes)
        }
      }
    })()
  }, [currentPeriod, subjects, programs])

  const handleCheck = (item, checked) => {
    let newConceptList

    if (checked) {
      newConceptList = [...conceptList, item]
    } else {
      newConceptList = conceptList.filter((el) => el.id !== item.id)
    }

    setConceptList(newConceptList)
  }

  React.useEffect(() => {
    const getSubjectEnrollments = async () => {
      if (Object.keys(currentProgram).length > 0) {
        setIsFetchingSubjectsEnroll(true)
        if (currentProgram.is_category) {
          const response = await xhr(
            `/course-enrollments?filters[student][id][$eq]=${idEst}&filters[course_academy][id][$eq]=${currentProgram.id}&populate=course`
          )

          setSubjectsEnrollmentsWithoutFilter(response.data.data)
          setSubjectsEnrollments(response.data.data.map((el) => el.course.id))
        } else {
          const response = await xhr(
            `/subject-enrollments?filters[student][id][$eq]=${idEst}&filters[subject][id][$gte]=1&filters[subject][${currentProgram.program_type.type.replace(
              '-',
              '_'
            )}][id][$eq]=${currentProgram.id}&pagination[limit]=-1&populate=*`
          )
          const filterIsTrial = response.data.data.filter(
            (e) => e.is_trial !== true
          )
          setSubjectsEnrollmentsWithoutFilter(response.data.data)
          setSubjectsEnrollments(filterIsTrial.map((el) => el.subject.id))
        }

        setIsFetchingSubjectsEnroll(false)
        setReload(false)
      }
    }

    getSubjectEnrollments()
  }, [Object.keys(currentProgram).length, reload])

  React.useEffect(() => {
    const getStudentPrograms = async () => {
      if (idEst) {
        setFetching(true)
        const response = await xhr(`/students/${idEst}/programs`)
        setPrograms(response.data)
        setFetching(false)
      }
    }

    getStudentPrograms()
  }, [idEst])

  const conceptListIds = conceptList.map((el) => el.id)

  const idSubjects = subjects.map((el) => {
    return el.id
  })

  const idSubjectsAlready = subjectEnrollments.filter(function (e) {
    return idSubjects.indexOf(e) > -1
  })

  React.useEffect(() => {
    ;(() => {
      setConceptListIdLast([...conceptListIds])
    })()
  }, [conceptListIds.length])

  //Para validar si la subject está matriculada pero con istrial=true

  const idSubjectsEnrollments = subjectsEnrollmentsWithoutFilter.map((el) => {
    return el.subject ? el.subject.id : el.course.id
  })

  const filterSubject = conceptListIds.filter(function (e) {
    return idSubjectsEnrollments.indexOf(e) > -1
  })

  const dataPayAlready = subjectsEnrollmentsWithoutFilter.find(
    (el) => (el.subject ? el.subject.id : el.course.id) === filterSubject[0]
  )

  const removeIdAlreadyEnroll = conceptListIdLast.filter(
    (el) => el !== filterSubject[0]
  )

  const postSubjects = async (idPayment) => {
    try {
      setIsPost(true)
      if (filterSubject.length === 1) {
        await xhr.put(`subject-enrollments/${dataPayAlready.id}`, {
          data: {
            is_trial: false,
          },
        })
      }

      if (removeIdAlreadyEnroll.length > 0) {
        removeIdAlreadyEnroll.map(async (ell) => {
          if (currentProgram.is_category) {
            const resCourse = await xhr.post(`course-enrollments`, {
              data: {
                status: 'estudiando',
                welcome: true,
                progress: 0,
                active: true,
                student: idEst,
                course: ell,
                course_academy: currentProgram.id,
              },
            })

            const getEnrollment = await xhr(
              `/course-enrollments/${resCourse.data.data.id}?populate=course.subject`
            )

            return await xhr.post(`subject-enrollments`, {
              data: {
                student: idEst,
                subject: getEnrollment.data.data.course.subject.id,
                course: ell,
              },
            })
          } else {
            await xhr.put(`/payments/${elPayment.id}`, {
              data: {
                subjects: noremove
                  ? [...idSubjectsAlready, ...conceptListIdLast]
                  : [...conceptListIdLast],
                revised: true,
              },
            })

            return await xhr.post(`subject-enrollments`, {
              data: {
                student: idEst,
                subject: ell,
                [currentProgram.program_type.type.replace('-', '_')]:
                  currentProgram.id,
              },
            })
          }
        })
      }

      if (!noremove && !payUpayment) {
        mutate(
          payments.filter((el) => {
            return el.id !== idPayment
          })
        )
      }

      setValue('')
      setActiveProgram(false)
      setConceptListIdLast([])
      setConceptList([])

      setReload(true)
      if (pagos) {
        setIsOpen2(false)
      }
      toasts({
        title: 'Las materias se han matriculado correctamente!',
        position: 'bottom',
      })
      setSuccess(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsPost(false)
    }
  }
  return (
    <>
      <Box>
        <Box>Matricúlale las materias al estudiante</Box>
        {fetching ? (
          <Spinner />
        ) : (
          <Select
            placeholder="Seleccione el programa"
            onChange={(event) => handleProgram(event.target.value)}
            marginY="15px"
            value={value}
          >
            {programs.map((el) => {
              const val = `${el.program_type.id}-${el.id}`
              return (
                <option key={el.id} value={val}>
                  {el.name}
                </option>
              )
            })}
          </Select>
        )}

        {isMenu && (
          <>
            <Select
              onChange={(e) => setCurrentPeriod(e.target.value)}
              width="30%"
              marginBottom="20px"
            >
              {Object.keys(subjectsPeriod)
                .sort()
                .map((el) => {
                  return (
                    <option key={el} value={el}>
                      {el.charAt(0).toUpperCase() + el.slice(1)} {periodText}
                    </option>
                  )
                })}
            </Select>
          </>
        )}

        {activeProgram && (
          <Box
            marginBottom="20px"
            border="1px solid #d9d9e1"
            borderColor="white900"
          >
            <Box
              backgroundColor="#cacbf9"
              color="#3d37a5"
              fontWeight="500"
              padding="8px"
              paddingX="10px"
            >
              ¿Qué materias paga el estudiante?
            </Box>

            {fetching2 ? (
              <Flex justifyContent="center" paddingY="15px">
                <Spinner />
              </Flex>
            ) : (
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                padding="10px"
                gridGap="12px"
                maxHeight="400px"
                overflowY="scroll"
              >
                {isFetchingSubjectsEnroll ? (
                  <Spinner />
                ) : (
                  <>
                    {subjects.map((el, i) => {
                      const isEnrolled = subjectEnrollments.includes(el.id)

                      return (
                        <Flex key={i}>
                          <Checkbox
                            checked={
                              isEnrolled || conceptListIds.includes(el.id)
                            }
                            isDisabled={isEnrolled}
                            textDecoration={isEnrolled && 'line-through'}
                            cursor={isEnrolled ? 'not-allowed' : 'pointer'}
                            onChange={(event) =>
                              handleCheck(el, event.target.checked)
                            }
                          >
                            {el.name}
                          </Checkbox>
                        </Flex>
                      )
                    })}
                  </>
                )}

                {!subjects.length && (
                  <Box textAlign="center">No se encontraron materias</Box>
                )}
              </Box>
            )}
          </Box>
        )}
        {success && (
          <Box
            variant="tint"
            title="Listo!"
            type="success"
            marginBottom="10px"
            marginTop="-17px"
          >
            Las materias se han matriculado correctamente
          </Box>
        )}
        {pagos && (
          <>
            <Button
              colorScheme="#574feb"
              backgroundColor="#574feb"
              isDisabled={
                isPost ? true : conceptListIds.length > 0 ? false : true
              }
              isLoading={isPost}
              onClick={() => postSubjects(elPayment.id)}
              marginRight="10px"
            >
              Matricular Materias
            </Button>
          </>
        )}
      </Box>
    </>
  )
}
