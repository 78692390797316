import React from 'react'
import {
  Box,
  Flex,
  Button,
  Image,
  Input,
  Select,
  Tag,
  Spinner,
  useToast,
} from '@chakra-ui/react'

import { Link, useParams, useNavigate } from 'react-router-dom'

import { HiTemplate } from 'react-icons/hi'

import xhr from 'xhr'
import CampaignName from './CampaignName'
import Receivers from './Receivers'
import useAuth from 'hooks/useAuth'

function IdCampaign() {
  const [campaign, setCampaign] = React.useState({
    name: '',
    programType: '',
    program: '',
    studentStatus: '',
    prospect_status: '',
    subject: '',
    category: '',
    students: 0,
    programTypes: [],
    programs: [],
    categories: [],
    prospect_program: '',
  })

  let params = useParams()

  const [categories, setCategories] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(true)
  const [isSaving, setIsSaving] = React.useState(false)
  const [saved, setSaved] = React.useState(false)
  const [isPublishing, setIsPublishing] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState('filter')

  const { user } = useAuth()

  const toast = useToast()
  const navigate = useNavigate()

  React.useEffect(() => {
    const getCampaign = async () => {
      try {
        const campaignResponse = await xhr(
          `/email-campaigns/${params.id}?populate=*`
        )
        const campaignResponseData = campaignResponse.data.data

        const categories = await getCategories()
        let campaignData = {
          students: campaignResponseData.receivers || 0,
          subject: campaignResponseData.subject || '',
          category:
            (campaignResponseData.category &&
              campaignResponseData.category.id) ||
            '',
          studentStatus: campaignResponseData.student_status || '',
        }

        if (campaignResponseData.category)
          campaignData.category = campaignResponseData.category.id

        setCampaign({ ...campaign, ...campaignResponseData, ...campaignData })
        setCategories(categories)
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetching(false)
      }
    }

    getCampaign()
  }, [])

  const getCategories = async () => {
    const categoriesResponse = await xhr(`/email-categories`)

    return categoriesResponse.data.data.map((el) => ({
      ...el,
      value: el.id,
      label: el.name,
    }))
  }

  const handleSave = async () => {
    try {
      if (!campaign.subject || !campaign.category) {
        return window.alert('Debes completar el asunto y la categoría')
      }

      setIsSaving(true)

      let payload = {
        subject: campaign.subject,
        category: campaign.category,
      }

      if (campaign.studentStatus)
        payload.student_status = campaign.studentStatus

      if (campaign.programType) payload.program_type = campaign.programType
      if (campaign.programType) payload.program_type = campaign.programType
      if (campaign.students > 0) payload.receivers = campaign.students
      if (campaign.prospect_status)
        payload.prospect_status = campaign.prospect_status
      if (campaign.global_prospect)
        payload.global_prospect = campaign.global_prospect
      if (campaign.prospect_program)
        payload.prospect_program = campaign.prospect_program
      if (activeTab === 'prospects') {
        payload.prospect = true
      } else {
        payload.prospect = false
      }

      await xhr.put(`/email-campaigns/${campaign.id}`, { data: payload })

      toast({
        title: '¡La campaña se ha guardado correctamente!.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
      setSaved(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const handlePublish = async () => {
    try {
      const r = window.confirm('¿Estás seguro que deseas publicar la campaña?')

      if (!r) return

      if (!campaign.subject || !campaign.category || !campaign.html) {
        return window.alert('Debes completar y guardar los datos de la campaña')
      }

      if (!campaign.students) {
        return window.alert('No hay estudiantes')
      }

      setIsPublishing(true)

      await xhr.post(`/email-campaigns/publish`, {
        data: {
          campaign: campaign.id,
        },
      })

      navigate('/plataforma/administrador/emails/envios')
    } catch (error) {
      console.error(error)
      setIsPublishing(false)
    }
  }

  return (
    <>
      {isFetching ? (
        <Flex justifyContent="center" height="70vh" alignItems="center">
          <Spinner size="large" />
        </Flex>
      ) : (
        <>
          <Flex
            backgroundColor="#fff"
            marginTop="-20px"
            marginX="-20px"
            borderBottom="1px solid"
            borderBottomColor="gray.300"
            paddingY="8px"
            paddingX="15px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tag colorScheme="gray" borderRadius="20px">
              Borrador
            </Tag>

            <Flex alignItems="center">
              <Button
                variant="outline"
                colorScheme="messenger"
                width="120px"
                paddingY="10px"
                marginRight="10px"
                onClick={handleSave}
                isLoading={isSaving}
                disabled={isSaving}
              >
                Guardar
              </Button>

              <Button
                colorScheme="#574feb"
                bg="#574feb"
                width="120px"
                paddingY="10px"
                marginLeft="10px"
                onClick={handlePublish}
                isLoading={isPublishing}
                isDisabled={
                  !saved ||
                  isPublishing ||
                  !campaign.subject ||
                  !campaign.category ||
                  !campaign.html ||
                  !campaign.students
                }
              >
                Publicar
              </Button>
            </Flex>
          </Flex>

          <Box
            paddingX="10px"
            maxWidth="85%"
            marginTop="15px"
            marginLeft="auto"
            marginRight="auto"
          >
            <Flex marginTop="10px" justifyContent="space-between">
              <CampaignName
                campaign={campaign}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            </Flex>

            <Box marginTop="15px">
              <Box marginTop="25px">
                <Box>
                  <Box fontSize="18px">Para</Box>

                  <Receivers
                    campaign={campaign}
                    setCampaign={setCampaign}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                  />
                </Box>
              </Box>

              <Box marginTop="25px">
                <Box fontSize="18px">Asunto</Box>
                <Input
                  value={campaign.subject}
                  onChange={(event) =>
                    setCampaign({ ...campaign, subject: event.target.value })
                  }
                />
              </Box>

              <Box marginTop="25px">
                <Box fontSize="18px">Categoría</Box>
                <Select
                  value={campaign.category}
                  placeholder="Seleccionar"
                  onChangeCapture={(event) =>
                    setCampaign({ ...campaign, category: event.target.value })
                  }
                >
                  {categories.map((el) => (
                    <>
                      <option value={el.value} key={el.value}>
                        {el.label}
                      </option>
                    </>
                  ))}
                </Select>
              </Box>

              <Box marginTop="25px">
                <Box fontSize="18px">Contenido</Box>

                {campaign.design ? (
                  <Box marginTop="10px">
                    <Flex alignItems="flex-end">
                      <Image
                        src="https://mcusercontent.com/3be1d5c4300e3828453e175f1/template-screens/8cf7a3b3d1b7b8a095c4fd815c182567_10018386_screen.png"
                        width="100px"
                        border="1px solid"
                        borderColor="white900"
                      />

                      <Link
                        to={`/plataforma/administrador/emails/envios/${campaign.id}/editor`}
                      >
                        <Button marginLeft="10px" use="a">
                          Editar
                        </Button>
                      </Link>
                    </Flex>
                  </Box>
                ) : (
                  <Link
                    to={`/plataforma/administrador/emails/envios/${campaign.id}/editor`}
                  >
                    <Flex
                      backgroundColor="#574feb"
                      padding="10px"
                      marginTop="10px"
                      borderRadius="4"
                      color="white"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                    >
                      <HiTemplate size={18} />
                      <Box use="a" marginLeft="minor-1">
                        Abrir editor
                      </Box>
                    </Flex>
                  </Link>
                )}
              </Box>

              <Box></Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default IdCampaign
