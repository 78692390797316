import React, { useState } from 'react'
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  TableContainer,
  Grid,
  GridItem,
  Button,
  Spinner,
} from '@chakra-ui/react'
import DatePicker from 'components/datePicker/DatePicker'

import { Link } from 'react-router-dom'
import xhr from 'xhr'
import dayjs from 'dayjs'

function Prospectos() {
  const [data, setData] = useState(undefined)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState('')
  const [inputData, setInputData] = useState('')
  const [dateFilter, setDateFilter] = React.useState(null)
  const [dateFilterEnd, setDateFilterEnd] = React.useState(null)

  const handleDateChange = (date) => {
    setDateFilter(date)
  }

  const handleDateChangeEnd = (date) => {
    setDateFilterEnd(date)
  }

  const getData = async () => {
    let url

    try {
      setLoading(true)
      setInputData(input)

      if (dateFilter) {
        let dateStart = `${dayjs(dateFilter).format('YYYY-MM-DD')}T05:00:00`

        let dateEnd = `${dayjs(dateFilterEnd).format('YYYY-MM-DD')}T23:59:59`
        const finalDate = dayjs(dateEnd)
          .add(5, 'hour')
          .format('YYYY-MM-DDTHH:mm:ss')
        if (!dateFilterEnd) return window.alert('Seleccione una fecha fin')
        url = `prospects?sort[0]=createdAt:desc&pagination[limit]=-1&populate=adviser.user&populate=country&filters[createdAt][$gte]=${dateStart}&filters[createdAt][$lte]=${finalDate}`
      } else if (input) {
        url = `prospects?filters[program][$eq]=${input}&sort[0]=createdAt:desc&pagination[limit]=-1&populate=adviser.user&populate=country`
      }
      const res = await xhr(url)
      setTotal(res.data.meta.pagination.total)
      setData(res.data.data)
    } catch (error) {
      console.log(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={2}>
        <GridItem colSpan={2}>
          <Input
            placeholder="Buscar por slug, EJ: curso-preicfes-intensivo-fb"
            value={input}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                getData()
              }
            }}
            onChange={(e) => setInput(e.target.value)}
          />
        </GridItem>

        <GridItem>
          <Box>
            <DatePicker
              date={dateFilter}
              placeholder="Fecha inicio"
              onChange={handleDateChange}
            />
          </Box>
        </GridItem>
        <GridItem>
          <Box>
            <DatePicker
              date={dateFilterEnd}
              placeholder="Fecha fin"
              onChange={handleDateChangeEnd}
            />
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Button
            w={'100%'}
            isLoading={loading}
            isDisabled={loading}
            colorScheme="#574feb"
            bg="#574feb"
            onClick={getData}
          >
            Buscar
          </Button>
        </GridItem>
      </Grid>
      <Box fontSize="18px" my="15px">
        Total de prospectos: {total}
      </Box>

      <TableContainer mt="10px">
        <Table variant="simple" size="sm">
          <Thead bg="gray.200">
            <Tr>
              <Th>#</Th>
              <Th>Nombres</Th>
              <Th>Email</Th>
              <Th>Celular</Th>
              <Th>Programa</Th>
              <Th>Estado</Th>
              <Th>Asesor/a</Th>
              <Th>Fecha Registro</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Spinner />
            ) : data?.length === 0 ? (
              <Box m="10px">
                No hay prospectos para tu criterio de busqueda: {inputData}
              </Box>
            ) : (
              data?.map((el, index) => {
                return (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="150px">
                        {el.name}
                      </Box>
                    </Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="150px">
                        {el.email}
                      </Box>
                    </Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="150px">
                        {el?.country ? `+${el?.country?.phone_code}` : ''}
                        {el.cellphone}
                      </Box>
                    </Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="150px">
                        {el.program}
                      </Box>
                    </Td>
                    <Td>{el.status}</Td>
                    <Td>
                      <Box whiteSpace="break-spaces" width="150px">
                        {el.adviser?.user?.first_name}{' '}
                        {el.adviser?.user?.last_name}
                      </Box>
                    </Td>
                    <Td>{dayjs(el.createdAt).format('YYYY-MM-DD')}</Td>
                  </Tr>
                )
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Prospectos
