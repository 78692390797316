import React from 'react'
import {
  Heading,
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Spinner,
  Input,
  InputLeftElement,
  InputGroup,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import ReactPaginate from 'react-paginate'
import { debounce } from 'throttle-debounce'

import xhr from 'xhr'
import { BiSearch } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import CreateTemplate from './CreateTemplate'
import BasicModal from 'components/modal/Modal'

export default function TemplatesAdmin() {
  const [templates, setTemplates] = React.useState([])
  const [templatesCount, setTemplatesCount] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [currentEmail, setCurrentEmail] = React.useState()
  const [searchText, setSearchText] = React.useState('')
  const [isSearching, setIsSearching] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState('')

  const perPage = 10

  const getTemplates = async ({ searchText, currentPage }) => {
    let query = `?filters[name][$containsi]=${searchText}&pagination[page]=${currentPage}&pagination[pageSize]=10&sort[0]=createdAt:DESC`

    const templatesResponse = await xhr(`/email-templates${query}`)

    setTemplates(templatesResponse.data.data)
    setTemplatesCount(templatesResponse.data.meta.pagination.pageCount)
  }

  React.useEffect(() => {
    getTemplates({ searchText, currentPage })
  }, [currentPage])

  React.useEffect(() => {
    searchTemplates(searchText)
  }, [searchText])

  const searchTemplates = React.useCallback(
    debounce(500, async (searchText) => {
      try {
        setIsSearching(true)

        await getTemplates({ searchText, currentPage })
      } catch (error) {
        console.error(error)
      } finally {
        setIsSearching(false)
      }
    }),
    []
  )

  const handleDelete = async (template) => {
    try {
      const r = window.confirm('¿Estás seguro de eliminar la plantilla?')

      if (!r) return

      setIsDeleting(template.id)

      await xhr.delete(`/email-templates/${template.id}`)

      setTemplates(templates.filter((el) => el.id !== template.id))
    } catch (error) {
    } finally {
      setIsDeleting('')
    }
  }
  return (
    <>
      <Box paddingX="15px">
        <Flex justifyContent="space-between" marginTop="15px">
          <Heading use="h1" marginBottom="0">
            Plantillas
          </Heading>

          <Button
            onClick={() => setIsOpen(true)}
            bg="#574feb"
            colorScheme="#574feb"
          >
            Crear plantilla
          </Button>
        </Flex>

        <Flex marginTop="15px">
          <InputGroup width="300px">
            <InputLeftElement
              pointerEvents="none"
              children={
                <Box position="absolute" top="12px" left="12px" color="gray200">
                  <BiSearch size={16} />
                </Box>
              }
            />
            <Input
              value={searchText}
              placeholder="Buscar plantilla"
              onChange={(event) => setSearchText(event.target.value)}
              isLoading={isSearching}
            />
          </InputGroup>
        </Flex>
        <TableContainer>
          <Table marginY="10px">
            <Thead bg="gray.200">
              <Tr>
                <Th>ID</Th>
                <Th>Plantilla</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {templates.map((template, index) => {
                return (
                  <Tr
                    key={template.id}
                    borderBottom="1px solid"
                    borderBottomColor="white900"
                  >
                    <Td verticalAlign="initial">{template.id}</Td>
                    <Td>
                      <Flex>
                        <Image
                          src="https://mcusercontent.com/3be1d5c4300e3828453e175f1/template-screens/8cf7a3b3d1b7b8a095c4fd815c182567_10018386_screen.png"
                          width="70px"
                          border="1px solid"
                          borderColor="white900"
                        />

                        <Box marginLeft="10px">
                          <Box fontWeight="500" fontSize="18px">
                            {template.name}
                          </Box>
                          <Box
                            fontSize="14px"
                            color="text100"
                            marginTop="minor-1"
                          >
                            Modificado el{' '}
                            {dayjs(template.createdAt).format('DD-MM-YYYY')}
                          </Box>
                        </Box>
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td width="120px">
                      <Flex alignItems="center">
                        <Button
                          colorScheme="whatsapp"
                          variant="outline"
                          alignSelf="flex-start"
                          use="a"
                          fontSize="14px"
                          mr="10px"
                          // onClick={() => {
                          //   setIsOpen2(true)
                          //   setCurrentEmail(template)
                          // }}
                          isDisabled
                        >
                          A
                        </Button>
                        <Link
                          to={`/plataforma/administrador/emails/plantillas/${template.id}/editor`}
                        >
                          <Button
                            colorScheme="messenger"
                            variant="outline"
                            alignSelf="flex-start"
                            use="a"
                            fontSize="14px"
                          >
                            Editar
                          </Button>
                        </Link>

                        <Button
                          marginLeft="10px"
                          onClick={() => handleDelete(template)}
                          _hover={{ color: 'red' }}
                          disabled={isDeleting === template.id}
                          colorScheme="red"
                          variant="outline"
                        >
                          {isDeleting === template.id ? (
                            <Spinner />
                          ) : (
                            <MdDelete size={18} cursor="pointer" />
                          )}
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {!isSearching && !templates.length && (
          <Flex justifyContent="center" marginY="15px">
            No se encontraron resultados
          </Flex>
        )}

        {!!templates.length && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(e) => setCurrentPage(e.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={templatesCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="containerReactPaginate"
            pageClassName="liReactPaginate"
          />
        )}

        <style jsx global>{`
          .containerReactPaginate {
            display: flex;
            width: inherit;
            justify-content: space-evenly;
            list-style: none;
            width: 300px;
          }

          .selected {
            color: #574feb;
            font-weight: bold;
          }
        `}</style>

        <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <CreateTemplate />
        </BasicModal>
      </Box>
    </>
  )
}
